import React from 'react'

export const About = () => {
    return (
        <div className="about">
            <div className="abt-title">
                <h1>INFO</h1>
            </div>
            <div className="col col-1">
                <p>
                    <span className="ligature">
                        Tervetuloa
                    </span> <i>Mad House Helsinki
                    Publication</i> ensimmäisen numeron pariin!
                </p>
                <p>
                    <i>
                        Mad House Helsinki Publication
                </i> on tila esitystaiteen tekijöille taiteellisen ajattelun jakamiseen. Julkaisu rakentuu taiteilijalähtöisesti käyttäen mm. videon, tekstin, äänen ja valokuvan mahdollisuuksia. Sisällöt asettuvat keskusteluun Mad House Helsingissä koettavien esitysten kanssa, mutta samalla julkaisu on oma itsenäinen kokonaisuutensa.

                </p>
                <p>
                    <i>Mad House Helsinki Publication</i> syntyi koronaviruspandemian seurauksena, kun esitystoiminta Mad Housen tilassa keskeytyi maaliskuussa 2020. Julkaisu ei kuitenkaan ole esityksiä korvaava ele, vaan rinnakkainen tila, joka jatkuu tulevien numeroiden myötä.

                </p>

                <p>
                    Ensimmäiseen numeroon kutsuttiin osallistumaan taiteilijoita, joiden teosten ja tapahtumien toteuttaminen Mad House Helsingin tiloissa kevään 2020 aikana oli pandemiasta johtuen epävarmaa. <b>Rakkaudesta</b>-työryhmä, <b>Prekaarit Praktiikat</b> ja <b>Liisa Pentti +Co</b> sekä BAD HOUSE -festivaalin kuraattorit <b>Sonja Jokiniemi</b> ja <b>Elina Pirinen</b> yhdessä taiteilija <b>Anne Naukkarisen</b> kanssa jakavat ajatuksiaan ja taidettaan.
                </p>
                <p>
                    Nautinnollisia hetkiä julkaisun parissa!
                </p>
                <p>
                    <b>Graafinen suunnittelu:</b>
                    <br />
                    Kiia Beilinson
                    <br />
                    Hanna Valle
                    <br />
                    <br />

                    <b>Ohjelmointi:</b>
                    <br />
                    Santeri Räisänen
                    <br />
                    <br />

                    <b>Julkaisun ovat koonneet:</b>
                    <br />
                    Anne Naukkarinen
                    <br />
                    Emmi Vainio

                </p>
            </div>
            <div className="col col-2">
                <p>
                    <span className="ligature">
                        Welcome
                    </span>  to the <i>Mad House Helsinki Publication</i>!
                </p>
                <p>
                    <i>Mad House Helsinki Publication</i> is a platform for living art and performance artists to share their artistic thinking. The publication is built to facilitate the artists’ preferred media, exploring the possibilities of video, text, sound, and photography, etc. While the articles converse with the Mad House Helsinki performances, the publication is an independent entity.

                </p>
                <p>
                    <i>Mad House Helsinki Publication</i> was born as a result of the Coronavirus pandemic, as performances at the Mad House were put on hold in March 2020. The publication is not a substitution for the performances, but rather a parallel platform, which extends with upcoming issues.

                </p>
                <p>
                    For the first issue, we invited contributions from those artists, whose performances and events at Mad House Helsinki were affected by the pandemic in the spring of 2020. The <b>Rakkaudesta</b> working group, <b>Prekaarit Praktiikat</b>, <b>Liisa Pentti +Co</b>, and the BAD HOUSE festival curators <b>Sonja Jokiniemi</b> and <b>Elina Pirinen</b>, together with artist <b>Anne Naukkarinen</b>, share their thoughts and their art.
                </p>
                <p>
                    We wish you enjoyable moments with the publication!
                </p>
                <p>
                    <b>Graphic design:</b>
                    <br />
                    Kiia Beilinson
                    <br />
                    Hanna Valle
                    <br />
                    <br />

                    <b>Programming:</b>
                    <br />
                    Santeri Räisänen
                    <br />
                    <br />

                    <b>Editors:</b>
                    <br />
                    Anne Naukkarinen
                    <br />
                    Emmi Vainio

                </p>
            </div>
            <div className="col col-3">
                <p>
                    <span className="ligature">
                        Välkommen
                    </span> till <i>Mad House Helsinki Publication</i>!
                </p>
                <p>
                    <i>Mad House Helsinki Publication</i> är en plattform för konstnärer att dela med sig av sitt konstnärligt tänkande. Nät publikationen är uppbyggd för att möjliggöra användningen av olika medier som video, text, ljud, foto, bilder med mera. Innehållet går i dialog med föreställningarna på Mad House, men publikationen fungerar också som en självständig helhet.

                </p>
                <p>

                    <i>Mad House Helsinki Publication</i> skapades som ett resultat av coronavirus-pandemin, när föreställningarna i Mad House avbröts i mars 2020. Publikationen ersätter inte föreställningarna, utan är en parallell plattform som fortsätter med nya nummer under de kommande åren.
                </p>
                <p>

                    Vi bjöd vi in ​​de konstnärer, som det var osäkert om deras verk och evenmang kunde förverkligas på Mad House på grund av pandemin under våren 2020 till det första numret. <b>Rakkaudesta</b> arbetsgruppen, Prekaarit Praktiikat, och <b>Liisa Pentti +Co</b>, samt BAD HOUSE festival kuratorerna <b>Sonja Jokiniemi</b> och <b>Elina Pirinen</b> delar sina tankar och sin konst tillsammans med konstnären <b>Anne Naukkarinen</b>.

                </p>
                <p>
                    Vi önskar er trevliga stunder med <i>Mad House Helsinki Publication!</i>

                </p>

                <p>
                    <b>Grafisk design:</b>
                    <br />
                    Kiia Beilinson
                    <br />
                    Hanna Valle
                    <br />
                    <br />

                    <b>Programmering:</b>
                    <br />
                    Santeri Räisänen
                    <br />
                    <br />

                    <b>Redaktion:</b>
                    <br />
                    Anne Naukkarinen
                    <br />
                    Emmi Vainio

                </p>
            </div>
        </div>
    )
}